.container {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  width: 500px;
}

/* Form */
.formTitle {
  font-weight: bold;
  padding: 1rem;
}

.formContainer {
  border-top: 1px solid hsl(224deg 8% 95%);
  padding: 1rem 1.25rem 2rem;
}

.formBody {
  border: 1.5px solid #d5d6d6;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
}

.formFooter {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

/* From and To */
.formInputContainer {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

.formInputTitle {
  font-size: 12px;
  font-weight: bold;
}

.formInput {
  flex: 1;
  padding: 0.75rem;
  outline: none;
  text-align: right;
}

/* Divider */
.formBody > * + * {
  border-top: 1px solid #d5d6d6;
}

/* Footer */
.footerTitle {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
}

.footerContainer {
  margin-top: 0.25rem;
  display: flex;
  gap: 0.5rem;
}

/* Amount */
.amountContainer {
  flex: 1;
}

.inputContainer {
  display: flex;
  align-items: center;
  border: 1.5px solid #d5d6d6;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
}

.dollarIcon {
  width: 1rem;
  height: 1rem;
}

.amountInput {
  padding: 0.725rem 0.5rem;
  outline: none;
  text-align: right;
  flex: 1;
}

.sendButton {
  background-color: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  font-weight: bold;
  padding: 0.725rem 3rem;
  border-radius: 0.25rem;
}

/* Swap */
.swapContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.swapTitle {
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
}

.swapIcon {
  color: var(--primary-color);
  width: 1rem;
  height: 1rem;
}
