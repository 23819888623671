.container {
  margin-top: 1.5rem;
  display: flex;
  width: 400px;
}

.bannerContent {
  background-color: white;
  padding: 2rem 1.5rem;
  width: 66.67%;
  border-radius: 0.25rem;
}

.bannerEmojis {
  display: flex;
}

.bannerTitle {
  font-weight: bold;
  font-size: 1.125rem;
  margin-top: 0.5rem;
}

.bannerDescription {
  margin-top: 1rem;
}

.bannerCta {
  display: flex;
  gap: 0.5rem;
  margin-top: 2rem;
}

.ctaButton {
  background-color: transparent;
  cursor: pointer;
}

.ctaButtonImage {
  height: 2rem;
  width: auto;
}

.bannerImageContainer {
  object-fit: cover;
  width: 33.33%;
  flex: 1;
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
