:root {
  --primary-color: #008cff;
  --gray: #2f3033;
}

/* Reset Styling */
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: black;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Global Styles */
html {
  font-family: system-ui;
}

body {
  color: black;
  background-color: #CCCC99;
}
