/* Containers */
.navigationContainer {
  background-color: #669999;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 72rem;
  margin: 0 auto;
  padding: 0.5rem 2rem;
}

.actionsContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.875rem;
}

/* Logo */
.logoContainer {
  width: 6rem;
  height: auto;
}

.logoImage {
  width: 100%;
  height: 100%;
}

/* Menu Dropdown */
.arrowDownIcon {
  width: 1rem;
  height: 1rem;
  color: #FFFFFF;
  stroke-width: 3px;
}

/* Avatar */
.avatarContainer {
  width: 3rem;
  height: 3rem;
}

.avatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 9999px;
}

.connectBtn {
  background-color: var(--primary-color);
  color: white;
  padding: 1rem 1rem;
  border-radius: 5px;
  font-weight: 800;
  cursor: pointer;
}

.accentColor {
  color: #FFFFFF;
  font-weight: bolder;
}
.title{

  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}